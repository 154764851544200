<template>
  <div class="app-container" v-loading="loading">
    <el-card>
      <el-row style="margin-left: 40px">
        <el-col :span="9">
          <div class="card1-input_date my-date-input date">
            <span style="padding-left: 5px; font-size: 13px" class="input-prefix">出场日期:</span>
            <el-date-picker
              v-model="dateRange"
              type="datetimerange"
              style="width: 75%"
              size="small"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']" 
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="-"
              @change="timeChanged"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="5">
          <el-select size="small" style="width: 90%" class="card1-input1" v-model="queryParams.workType" clearable placeholder="请选择">
            <el-option :label="'全部'" :value="null"> </el-option>
            <el-option :label="'白班'" :value="'DAY_WORK'"> </el-option>
            <el-option :label="'夜班'" :value="'NIGHT_WORK'"> </el-option>
            <template slot="prefix"><span class="input-prefix">班制：</span></template>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select size="small" style="width: 95%" class="card1-input" v-model="queryParams.projectId" clearable placeholder="请选择">
            <el-option v-for="item in bossProList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            <template slot="prefix"><span class="input-prefix">工地项目：</span></template>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button style="margin-top: 3px" @click="getData" type="primary" class="el-icon-search">查 询</el-button>
        </el-col>
      </el-row>
      <div style="margin-top: 10px">
        <v-chart :forceFit="true" :height="height" :data="chartData" :scale="scale">
          <v-tooltip />
          <v-axis />
          <v-line position="day*总工单" :size="2" shape="smooth" />
          <v-area position="day*总工单" shape="smooth" />
        </v-chart>
      </div>
    </el-card>
    <el-card style="margin-top: 5px">
      <div slot="header">
        <el-radio-group v-model="radio1">
          <el-radio-button label="汇总统计"></el-radio-button>
          <el-radio-button label="分日统计"></el-radio-button>
        </el-radio-group>
        <el-button style="float: right" @click="exportDataEvent">下载表格<i class="el-icon-download el-icon--left"></i></el-button>
      </div>
      <el-row>
        <vxe-table ref="xTable1" border show-header-overflow show-overflow highlight-hover-row :data="tableData">
          <vxe-table-column align="center" type="seq" title="序号" width="60"></vxe-table-column>
          <vxe-table-column align="center" field="date" title="日期"></vxe-table-column>
          <vxe-table-column align="center" field="workType" title="班制"></vxe-table-column>
          <vxe-table-column align="center" field="projectName" title="工地项目"></vxe-table-column>
          <!-- <vxe-table-column align="center" field="zdCount" title="自倒" />
          <vxe-table-column align="center" field="ztcCount" title="渣土场"></vxe-table-column> -->
          <vxe-table-column align="center" field="count" title="合计"> </vxe-table-column>
        </vxe-table>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { projectList, projectStt } from '@/api/stt'
import dayjs from 'dayjs'
const scale = [
  {
    dataKey: '总工单',
    min: 0
  }
]
export default {
  data() {
    return {
      loading: false,
      dateRange: [],
      chartData: [], // 统计图数据
      scale,
      height: 400,
      radio1: '汇总统计',
      tableData: [],
      allAlign: null,
      bossProList: [],
      // 查询条件表单
      queryParams: {
        startTime: '',
        endTime: '',
        projectId: null,
        workType: null,
        type: '0'
      },
      dayStatistics: [], // 分日统计
      summaryStatistics: [] // 汇总统计
    }
  },
  watch: {
    radio1(val, oldVal) {
      if (val === '汇总统计') {
        this.tableData = this.summaryStatistics
      } else {
        this.tableData = this.dayStatistics
      }
    }
  },
  created() {
    let curentMon = new Date()
    let monFirstDay = dayjs(curentMon).startOf('month').format('YYYY-MM-DD HH:mm:ss')
    let monLastDay = dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss')
    this.dateRange.push(monFirstDay)
    this.dateRange.push(monLastDay)
    this.getProjectList()
    this.getData()
  },
  methods: {
    timeChanged(val) {
     console.log(val)
    },
    // 获取该老板所有的工地
    getProjectList() {
      projectList().then(res => {
        let arr = [{ id: null, name: '全部项目' }]
        res.data.forEach(item => {
          arr.push({ id: item.id, name: item.name })
        })
        this.bossProList = arr
      })
    },
    // 获取统计数据
    getData() {
      this.loading = true
      this.queryParams.startTime = this.dateRange[0]
      this.queryParams.endTime = this.dateRange[1]
      projectStt(this.queryParams).then(res => {
        this.dayStatistics = res.data.dayStatistics
        this.summaryStatistics = res.data.summaryStatistics
        if (this.radio1 === '汇总统计') {
          this.tableData = this.summaryStatistics
        } else {
          this.tableData = this.dayStatistics
        }
        let totalOrderMapArr = []
        let totalOrderMap = [...Object.values(res.data.totalOrderMap)]
        totalOrderMap.forEach(item => {
          let obj = { day: item.key, 总工单: item.count }
          totalOrderMapArr.push(obj)
        })
        this.chartData = totalOrderMapArr
        this.loading = false
      })
    },
    // 导出
    exportDataEvent() {
      this.$refs.xTable1.exportData({ filename: this.radio1, type: 'csv' })
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.date .el-input__inner {
  border: none;
}
.my-date-input {
  border: 1px solid #dcdfe6;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  width: 90%;
  height: 45px;
  line-height: 40px;
}
.date .el-date-editor {
  margin-top: -5px !important;
}
.card1-input .el-input__prefix {
  left: 5px !important;
  padding-top: 12px !important;
}
.el-card__header {
  border-bottom: none;
}
.card1-input .el-input--prefix .el-input__inner {
  padding-left: 80px;
}
.card1-input1 .el-input__prefix {
  left: 5px !important;
  padding-top: 12px !important;
}
.card1-input1 .el-input--prefix .el-input__inner {
  padding-left: 60px;
}
.card1-input_date .input-prefix {
  font-style: normal;
  font-weight: 650;
  color: #409eff;
}
.card1-input .input-prefix {
  font-style: normal;
  font-weight: 650;
  color: #409eff;
}
.card1-input1 .input-prefix {
  font-style: normal;
  font-weight: 650;
  color: #409eff;
}
.card1-input1 .el-input__inner {
  height: 45px;
  line-height: 40px;
}
.card1-input .el-input__inner {
  height: 45px;
  line-height: 40px;
}
.el-date-editor .el-range-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  display: inline-block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #606266;
}
</style>
